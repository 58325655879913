import { useEffect, useState } from 'react';
import './App.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
var cloneDeep = require('lodash.clonedeep');




function App() {

  const [playerName, setPlayerName] = useState('');
  const [playerBuyInAmount, setPlayerBuyInAmount] = useState();
  const [playerFinalAmount, setPlayerFinalAmount] = useState();
  const [players, setPlayers] = useState({});
  const [results, setResults] = useState([]);
  const [netAmount, setNetAmount] = useState(0);
  const [error, setError] = useState('');
  const [calculateError, setCalculateError] = useState('');

  let winners = [];
  let losers = [];



  let calculatedAlready = false;

  function resetInputs() {
    setPlayerName('');
    setPlayerBuyInAmount('');
    setPlayerFinalAmount('');
  }

  function validateInputs() {
    if (!playerName) {
      setError('Please input a name');
      return false;
    }
    else if (!playerBuyInAmount) {
      setError('Please enter Buy In amount');
      return false;
    }
    else if (!playerFinalAmount) {
      setError('Please enter Final amount');
      return false;
    }
    return true;
  }

  function addPlayerObject() {
    setError('');
    if (!validateInputs()) {
      return;
    }

    setPlayers({
      ...players,
      [playerName]: {
        'playerName': playerName,
        'buyIn': parseFloat(playerBuyInAmount),
        'finalAmount': parseFloat(playerFinalAmount),
        'netAmount': parseFloat((playerFinalAmount - playerBuyInAmount).toFixed(2)),
      }
    });
    resetInputs();


  }

  function checkNetAmount() {
    if (netAmount !== 0) {
      setError('The net amount column must equal 0 but it equals ' + netAmount.toFixed(2));
      setResults([]);
    } else {
      setError('');
    }
  }

  useEffect(() => {
    checkNetAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netAmount]);

  function addFloats(num1, num2) {
    let num1Float = parseFloat(num1.toFixed(2));
    let num2Float = parseFloat(num2.toFixed(2));
    let total = parseFloat((num1Float + num2Float).toFixed(2));

    return total
  }

  function calculateResults(losingPlayer, winningPlayer, netAmount) {
    return losingPlayer.playerName + " pays " + winningPlayer.playerName + " $" + Math.abs((netAmount).toFixed(2));
  }

  function calculatePayments() {

    let tempNetAmount = 0;
    setResults([]);
    setCalculateError('');
    let tempWinners = [];
    let tempLosers = [];

    //Create a temp array to work with so that the orignal players array data is not modified so we can do repeat calculations
    let tempPlayers = cloneDeep(players);

    // eslint-disable-next-line array-callback-return
    Object.entries(tempPlayers).map(([key]) => {
      const player = tempPlayers[key];

      //track the current net amount
      tempNetAmount = addFloats(tempNetAmount, player.netAmount);
      if (player.netAmount < 0) {
        if (!calculatedAlready) {
          losers.push(player);
        }
        tempLosers.push(player);

      } else {
        if (!calculatedAlready) {
          winners.push(player);
        }
        tempWinners.push(player);
      }
    });
    if (tempNetAmount !== 0) {
      setCalculateError('The net amount column must equal 0 but it equals ' + tempNetAmount.toFixed(2));
      setResults([]);
      return;
    }

    calculatedAlready = true;

    //sort the amounts 
    tempLosers.sort((a, b) => {
      return a.netAmount - b.netAmount;
    });
    tempWinners.sort((a, b) => {
      return b.netAmount - a.netAmount;
    });

    let losersIndex = 0;
    let winnersIndex = 0;

    //Iterate through losing players and calculate who they need to pay
    while (losersIndex !== losers.length && winnersIndex !== winners.length) {
      let losingPlayer = tempLosers[losersIndex];
      let winningPlayer = tempWinners[winnersIndex];
      if (losingPlayer.netAmount + winningPlayer.netAmount > 0) {
        tempNetAmount = addFloats(tempNetAmount, winningPlayer.netAmount);
        winningPlayer.netAmount = addFloats(losingPlayer.netAmount, winningPlayer.netAmount);
        losersIndex++;
        setResults(results => [...results, calculateResults(losingPlayer, winningPlayer, losingPlayer.netAmount)]);
      } else {
        if (losingPlayer.netAmount + winningPlayer.netAmount === 0) {
          setResults(results => [...results, calculateResults(losingPlayer, winningPlayer, winningPlayer.netAmount)]);
          losersIndex++;
          tempNetAmount = 0;
        } else {
          let s = calculateResults(losingPlayer, winningPlayer, winningPlayer.netAmount);
          tempNetAmount = addFloats(tempNetAmount, losingPlayer.netAmount);
          setResults(results => [...results, s]);
          losingPlayer.netAmount = addFloats(losingPlayer.netAmount, winningPlayer.netAmount);
        }
        winnersIndex++;
      }
    }
    tempPlayers = JSON.parse(JSON.stringify(players));
    setNetAmount(tempNetAmount);
  }


  return (
    <Container className='mt-4'>
      <Row>
        <h1 className='text-center mb-4 fw-bold'>
          Chip Calculator
        </h1>
      </Row>
      <Row>
        <Col xs={12} className='d-flex flex-column align-items-center'>
          <input className='w-50' value={playerName} onChange={(e) => setPlayerName(e.target.value)} placeholder='Name' />
          <input inputMode="decimal" type='number' className='w-50 my-3' step="0.01" value={playerBuyInAmount} onChange={(e) => setPlayerBuyInAmount(e.target.value)} placeholder='Buy In Amount' onFocus={() => setPlayerBuyInAmount()} />
          <input inputMode="decimal" type='number' className='w-50' step="0.01" value={playerFinalAmount} onChange={(e) => setPlayerFinalAmount(e.target.value)} placeholder='Final Amount' />
        </Col>
        <Col className='w-100 d-flex justify-content-center mt-3' xs={12}>
          <Button className='w-50 mt-3 fw-bold' variant="success" onClick={addPlayerObject}>
            Add Player
          </Button>

        </Col>
      </Row>

      {error ? <div className='error mt-3 text-center'>
        <Alert variant='danger'>
          {error}
        </Alert>
      </div> : null}
      {Object.keys(players).length > 0 ? <>
        <Row>
          <div>
            <Table striped bordered hover size="sm" responsive className='mt-3'>
              <thead>
                <tr>
                  <th>Player</th>
                  <th>Buy In</th>
                  <th>Final Amount</th>
                  <th>Net Amount</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(players).map(([key, value]) =>
                  <tr key={value}>
                    <td>
                      {players[key].playerName}
                    </td>
                    <td>
                      {players[key].buyIn}
                    </td>
                    <td>
                      {players[key].finalAmount}
                    </td>
                    <td>
                      {players[key].netAmount.toFixed(2)}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Row>
        <div className='text-center'>
          <Button disabled={playerName || playerBuyInAmount || playerFinalAmount} className='mt-3 w-100 fw-bold' variant="success" onClick={() => calculatePayments()}>
            Calculate
          </Button>
        </div>
        {calculateError ? <div className='error mt-3 text-center'>
          <Alert variant='danger'>
            {calculateError}
          </Alert>
        </div> : null}


        <Table striped bordered hover size="sm" responsive className='mt-4'>
          <tbody>
            {results.map((item, index) => <tr key={index}>
              <td>
                <h4>
                  {item}
                </h4>
              </td>
            </tr>
            )}
          </tbody>
        </Table></> : null}


    </Container>
  );
}

export default App;
